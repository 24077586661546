.mycontainer {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  
  position: relative;
}
.backgroundVideo{
  position: absolute;
  z-index: -10;
  width: 100%;
  height: 100vh;
  background: #011557;
  display: flex;
  align-items: center;
  justify-content: center;

}
.backgroundVideo video{
  width: 100% ;
  mix-blend-mode: lighten !important;

}
.mobileCode{
  width: 20rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.flags{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  

}
.flags input{
  width: 100% !important;
}
.flags ul{
  height: 50vh;
  width: 30%;
  overflow-y: scroll;
  z-index: 1000;
  list-style: none;
  border: 2px solid black;
  border-radius: 10px;
  display: none;
}
.flags img{
  width: 10px;
  height: 10px;
  object-fit: cover;

}

.mobileCode input{
  width: 100% !important;
  margin: auto 0;
}
.navbar {
  width: 100%;
  height: 5vh;
  /* background: black; */
  position: absolute;
  top: 1rem;
  padding: 1rem 5rem;
  display: flex;
  align-items: center;
}
.navbar img {
  height: 5vh;
}
.formbody{
  position: relative;
}
.flagSelect{
  width: 100%;
}

.crossIcon{
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 30px;
  transition: 250ms ease-out;
  cursor: pointer;
}
.crossIcon:hover{
  transition: 500ms ease-in;
  transform: rotate(90deg);
}
.midheading {
  text-shadow: none;
  color: white;
  font-weight: bold;
  font-size: 2rem;
  /* margin: 0 auto; */
  text-align: center;
  margin-left: 1rem;
}


@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
label h1 p {
  font-family: "Poppins", sans-serif;
}
.formbody {
  width: 500px;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  flex-direction: column;
  z-index: 100;

  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
}
.brandLogo {
  width: 250px;
}
.flagContainer{
  width: 30%;
  border: 2px solid black;
  border-radius: 10px;
  
}
.heading {
  text-align: center;
  font-weight: bold;
  /* position: absolute; */
  /* top: 10vh; */
  /* margin: 0 auto; */
  /* display: flex; */
  margin-bottom: 2rem;
  /* background: yellow; */

  color: #011557;
}
.inputs {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  text-align: left;

  flex-direction: column;
}
.inputs label {
  font-weight: bold;
}
.inputs input {
  margin-bottom: 1rem;
  width: 20rem;
  height: 3rem;
  border-radius: 10px;
  /* border: none; */
  outline: black;
  padding: 0 1rem;
  border: 2px solid black;
}
.result {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #011557;
}
.inputs button {
  background: #011557;
  color: white;
  padding: 1rem 0;
  border: none;
  width: fit-content;
  padding: 0.3rem 3rem;
  margin: 0 auto;
  border-radius: 5px;
  font-weight: bold;
}
.register {
  display: flex;
}

.register a {
  margin-left: 5px;
  color: #011557;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.forget {
  /* background: #C62828; */
  color: black;
  width: fit-content;

  border-radius: 5px;
  margin-bottom: 1rem;
  cursor: pointer;
  margin-top: 1rem;
}
.forget a {
  color: #011557;
  font-weight: bold;
}
@media screen and (max-width: 1000px) {
  .navbar {
    width: 100%;
    height: 5vh;
    /* background: black; */
    position: absolute;
    top: 1rem;
    padding: 1rem;
    display: flex;
    align-items: center;
  }
}
