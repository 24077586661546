.section4Container{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.section4Socials{
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: space-between;
}
.section4Socials p{
    font-size: 20px;
    margin: auto 0;

}
.section4Socials i{
    font-size: 30px;
}
.section4Content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}
.section4Content p{
    font-size: 20px;
    color: black;
    text-align: center;

}