.navBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  width: 100%;
  position: relative;
  z-index: 1001;
}
.dropdown2{
  width: 3rem;
  height: 1.5rem;
  user-select: none;
  margin: 0 auto;
  position: relative;
  position: absolute;
  left: 22%;
  border: none !important;
  cursor: pointer;
  z-index: 1000;
  top: .8rem;
  



}
.brandLogo h1{
  margin: auto 0;
}
.dropdown2 img{
  width: 100%;
  height: 2rem ;
  object-fit: contain;
  z-index: 1000;
  
}
.selectedImage{
  height: 2rem;
  margin: 0 auto;

}
.dropdown2 .dropdown2-btn{

  /* background: rgb(24, 14, 14); */
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;

}
.flagContainer{
  width: 8rem;
  z-index: 1000;
  position: absolute;
  left: 22%;
  cursor: pointer;
}
.Disable{
  opacity: .5;
  filter: saturate(0);
}

.flagImage{
  width: 45%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flagImage img{
  width: 100%;
  height: 2rem !important;
  object-fit: contain;
}
.dropdown2 .dropdown2-content{
  position: absolute;
  top: 200%;
  padding: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.063);
  font-weight: 700;
  color: #333;
  left: 0;
  width:10rem;
  overflow-x: hidden;
  height: fit-content;
  display: flex;
  
  align-items: center !important;
  justify-content: center;
  /* flex-direction: column; */

}
.dropdown2-item-image{
  width: 3rem;
}
.dropdown2-item img{
width: 100% !important; 
height: 2rem !important;
}

.dropdown2 .dropdown2-content .dropdown2-item{
  padding: 10px;
  transition: 250ms ease-out;
cursor: pointer;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;

}
.dropdown2-item p{
  font-size: 18px;
  margin: auto 0;
}
.dropdown2 .dropdown2-content .dropdown2-item:hover{
  background: #f4f4f4;
  transition: 150ms ease-in;
}

.navBarlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;

  list-style: none;
}
.navBarlist i{
  font-size: 25px;
}

li {
  cursor: pointer;
  font-size: 20px;
}

.customNavBarItems{
  transition: 150ms ease-out;
}
.customNavBarItems:hover{
  transition: 150ms ease-in;
  background: black;
  border-radius: 10px;
  padding: .5rem;
  color: white;
}
.navBarLogo{
  width: 9vh !important;
  height: 9vh !important;
}
.brandLogo{
  display: flex;
  align-items: center;
  justify-content: end;
  width: 20%;
 


}
.brandLogo img{
  /* width: 10px !important; */
  object-fit: contain;
  height: 70px !important;

}
.navBarButton {
  background: black;
  border: none;
  outline: none;
  font-size: 25px;
  margin-right: 1rem;
  margin-top: 1rem;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: .5rem 1rem;
  color: white;
  font-weight: bold;
  transition: 250ms ease-out;
  width: 8rem;
  z-index: 1000;

}
.navBarButton:hover{
  transition: 250ms ease-in;
  -webkit-box-shadow: 0px 0px 20px 0px rgb(0, 0, 0);
-moz-box-shadow: 0px 0px 20px 0px rgb(0, 0, 0);
box-shadow: 0px 0px 20px 0px rgb(0, 0, 0);
}
.quickstartHover {
  display: none;
  transition: 250ms ease-out;
}
.quickstartHoverToggle {
  position: absolute;
  top: 15vh;
  display: block;
  width: 100%;
  transition: 250ms ease-in;
  padding: 2rem;
  background: #011557;
  z-index: 21;
}
#ToggleButton {
  display: none;
}
#backArrow{
  display: none;
}
.quickstartHoverToggle h5{
  font-weight: bold;
}
.quickstartHoverToggle p{
  transition: 250ms ease-out;
}
.quickstartHoverToggle p:hover{
  background: black;
  color: white;
  transition: 250ms ease-in;
  padding: 1rem;
  border-radius: 20px;
}
@media screen and (max-width: 1206px) {
  .navBarlist {
    display: none;
  }
  .dropdown2{
   width: 3rem;
    left: 52%;
    
  }
  .flagContainer{
    width: 8rem;
    z-index: 1000;
    position: absolute;
    left: 22%;
    cursor: pointer;
    left: 52%;

  }
  .dropdown2-content{
    right: 10% !important;
    left: auto !important;
    z-index: 100;
  }
  #backArrow{
    display: block;
    font-size: 30px;
    margin-bottom: 1rem;
  }
  #ToggleButton {
    display: block;
  }
 .navBarContainer ul li{
   font-size: 20px;
 }
  .toggleNav {
    width: 100%;
    background:#011557;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    list-style: none;
    position: absolute;
    top: 10vh;
    z-index: 20;

    right: 0;
  }
  .toggleNav li{
    color: white;
  }
  .toggleNav i{
    color: white !important;
  }
  .brandLogo{
    display: flex;
    align-items: center;
    justify-content: end;
    width: 50%;
   
  
  
  }
  .brandLogo h1{
    margin:  auto 0;
  }
  .brandLogo img{
    width: 50px !important;
    height: 50px !important;
    margin: 10px 10px !important;
  
  }
}
