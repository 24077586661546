.customCircle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  min-height: 10vh;
}
.circle {
  width: 10vh;
  height: 10vh;
  border-radius: 50%;
  display: flex;
  background: linear-gradient(to bottom right, #0c7683, #22bc6d);
  align-items: center;
  justify-content: center;
}
.innerCircle {
  width: 9.5vh;
  height: 9.5vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background: #0b121c;
  align-items: center;
  color: white;
  font-size: 30px;
}
.arrow {
  width: 35%;
  height: 0.2rem;
  background: linear-gradient(to right, #0c7683, #22bc6d);
}
.circleContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin: 2rem 0;
  width: 100%;
}
.circleContent div {
  width: 20rem;
}
.parentCircleContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.circleButton {
  background-color: #00beff;
  border: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  color: black;
  padding: 1rem 2rem;
  font-size: 20px;
  font-weight: bold;
}
@media screen and (max-width: 1000px) {
  .parentCircleContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .customCircle {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 60vh;
    width: fit-content !important;
  }
  .arrow {
    transform: rotate(90deg);
    margin: 0 auto;
    width: 100%;
  }
  .circleContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
    margin: 2rem 0;
    width: 100%;
    height: 60vh;
  }
  .circleContent div {
    max-width: 15rem;
  }
  .circle {
    max-width: 10vh !important;
    max-height: 10vh !important;
    border-radius: 50%;
    display: flex;
    background: linear-gradient(to bottom right, #0c7683, #22bc6d);
    align-items: center;
    justify-content: center;
  }
  .innerCircle {
    max-width: 9.5vh !important;
    max-height: 9.5vh !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background: #0b121c;
    align-items: center;
    color: white;
    font-size: 30px;
  }
}
