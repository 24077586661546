.dashboardContainer {
  position: relative;

  height: 85vh;
  background: linear-gradient(to top ,  #B6E3F8, #DCEEFA);
}
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');

.dashboardContainer img {
  
  mix-blend-mode: darken;
  height: 70vh;
  width: 100% !important ;
}

.Dashboardheading{
  width: 60%;
}
.Dashboardheading h1{
  /* font-weight: 700; */
  color: black;
  font-size: 55px;
  font-family: "PT Sans", sans-serif;


}
.Section_top {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: url(../../Assets/1_veW_bUCkcsHZ32GMCVhyLA.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  justify-content: center;
  animation: change 20s infinite ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
}
.Section_top video{
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}
.content h1 {
  color: #fff;
  font-size: 60px;
  letter-spacing: 15px;
}
.content h1 span {
  color: #111;
}
.content a {
  background: #85c1ee;
  padding: 10px 24px;
  text-decoration: none;
  font-size: 18px;
  border-radius: 20px;
}
.content a:hover {
  background: #034e88;
  color: #fff;
}
/* @keyframes change {
  0% {
    background-image: url(../../Assets/1_veW_bUCkcsHZ32GMCVhyLA.png);
  }
  20% {
    background-image: url(../../Assets/nicholas-cappello-Wb63zqJ5gnE-unsplash.jpg);
  }
  40% {
    background-image: url(../../Assets/1_veW_bUCkcsHZ32GMCVhyLA.png);
  }
  60% {
    background-image: url(../../Assets/nicholas-cappello-Wb63zqJ5gnE-unsplash.jpg);
  }
  80% {
    background-image: url(../../Assets/1_veW_bUCkcsHZ32GMCVhyLA.png);
  }
  100% {
    background-image: url(../../Assets/nicholas-cappello-Wb63zqJ5gnE-unsplash.jpg);
  }
} */
@media screen and (max-width : 1000px) {
  .Dashboardheading{
    width: 100% !important;
   
  }
  .myContent{
    display: flex;
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    min-height: 70vh;
    text-align: center;
  
    
  }
  .Dashboardheading h1{
font-size: 30px;  }
.dashboardContent{
  position: relative;
}
.mobileBackground{
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  filter: blur(5px);
}
  
}