.homecontainer {
  position: relative;
}
.homeBackground {
  position: absolute;
  top: 5vh;
  z-index: -100;
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.homeBackground img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
