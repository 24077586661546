
.section-3{
    min-height: 70vh;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.section-3 h3{
    color: white;
    font-weight: bold;
}
.section-3 i{
    font-size: 70px;
   
}
.section-3 p{
    font-size: 20px;
}
.section3Link{
    width: 12rem;
    max-width: 250px;
    padding: 1rem;
    color: white;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    border-width: 3px;
    text-decoration: none;
    transition: 250ms ease-out;
    border-style: solid;
    border-image: 
      linear-gradient(
        to bottom, 
        white, 
        rgba(0, 0, 0, 0)
      ) 1 100%;
}
.section3Link:hover{
    background: #0077ff;
    color: white;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,136,255,1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,136,255,1);
    box-shadow: 0px 0px 20px 0px rgba(0,136,255,1);
}

@media screen and (max-width : 950px) {
    .section3number{
        margin-bottom: 2rem;
    }
    .section-3 a{
        font-size: 18px;
    }
    
}