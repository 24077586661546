* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");
body {
  font-family: "PT Sans", sans-serif;
  overflow-x: hidden;
}
h1 {
  font-weight: 700;
}
.tickImage{
  min-height: 60vh;
  align-items: center;
  display: flex;
}
.footerList li{
  font-size: 18px;

}
.tickImage img {
  width: 30px;
  margin: auto 10px;
  height: 30px;
}
.tickImage p {
  font-size: 20px;
  margin: auto 0;
}
.diagonalImage {
  width: 100%;
  height: 60vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ssl{
  height: 50px;
  margin: auto 0;
  object-fit: contain;
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;

  /* Safari seems to support, but seems deprecated and does the same thing as the others. */
  image-rendering: -webkit-optimize-contrast;
}
.diagonalImage img {
  height: 100%;
  object-fit: cover;
position: absolute;
bottom: 0;
  z-index: -100;
  right: 0;
}
.section5-container {
  background: #0b121c;
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section6-button button {
  border: none;
  outline: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 0.5rem 1rem;
  background-color: #00beff;
  color: black;
  font-weight: bold;
}
.customFooter p {
  font-size: 18px;
}
.underLine {
  height: 3px;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 190, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
.Disclosures{
  width: 70% !important;
}

@media screen and (max-width: 1000px) {
  .customCarousel img {
    width: 90%;
  }
  .footerSocialContainer{
    justify-content: center !important;
  }
  .footerUnderLink a{
    font-size: 12px;

  }
  .Disclosures{
    width: 100% !important;
  }
  .sslcontainer{
    margin-top: 2rem;
  }
}
